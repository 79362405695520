import { inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { distinctUntilChanged, Observable } from 'rxjs';
import { convertCSSUnitInPixel } from '../functions/numbers';


export interface CSSCustomPropertyPseudoElementWorkaroundObject {
  /**
   * the CSS Custom Property name of the source element
   */
  sourcePropertyName: string;
  /**
   * can be set directly of if not set it will be selected by the sourceElementCSSSelector
   */
  sourceElement?: HTMLElement;
  /**
   * by default: parentElement is just the root element but it can be set because it needs to be specific
   * (i. e. target is in shadow root) or it is set due to performance saving reasons
   */
  sourceParentElement?: HTMLElement;
  /**
    * will be used if there is no "sourceElement" set
   */
  sourceElementCSSSelector?: string;
  /**
   * by default: ${sourcePropertyName}-pewa
   * 'pewa' pseudo element workaround
   */
  targetPropertyName?: string;
  /**
   * can be set directly of if not set it will be selected by the targetElementCSSSelector
   */
  targetElement?: HTMLElement;
  /**
   * by default: parentElement is just the root element but it can be set because it needs to be specific
   * (i. e. target is in shadow root) or it is set due to performance saving reasons
   */
  targetParentElement?: HTMLElement;
  /**
   * will be used if there is no "targetElement" set
  */
  targetElementCSSSelector?: string;
}

@Injectable({providedIn: 'root'})
export class UtilCustomCSSPropertyService {

  private static noScrollAttemptsMap = new WeakMap<HTMLElement, number>();
  private static preAttemptOverflowYValueMap = new WeakMap<HTMLElement, string>();

  private readonly windowRef = inject(WindowRef);

  private getElement(domElement?: HTMLElement) {
    return domElement || this.windowRef?.document.querySelector(':root');
  }

  setValue(variable: string, value: string = 'unset', domElement?: HTMLElement) {
    domElement = this.getElement(domElement);
    if (domElement) {
      domElement?.style?.setProperty(variable as string, value);
    }
  }


  remove(variable: string, domElement?: HTMLElement) {
    domElement = this.getElement(domElement);
    if (domElement) {
      domElement?.style?.removeProperty(variable as string);
    }
  }

  getValue(variable: string, domElement?: HTMLElement) {
    domElement = this.getElement(domElement);
    if (domElement) {
      const styles = this.windowRef.nativeWindow.getComputedStyle(domElement);
      return styles?.getPropertyValue(variable as string);
    }
    return void 0;
  }

  /**
   * observes a css custom variable by periodically test (asynchronously) the current value and updates the value
   * Note: not yet battle tested
   */
  observeValue(variable: string, domElement?: HTMLElement, initialValue?: string) {
    return new Observable<string>(subscriber => {

      domElement = this.getElement(domElement);

      let frameId: number;
      let value: string;
      let oldValue = initialValue;

      const css = getComputedStyle(domElement);

      const getValueAndCheckFn = () => {
        value = css.getPropertyValue(variable).trim();

        if (value !== oldValue) {
          oldValue = value;
          subscriber.next(value);
        }
      };

      const animationFrameFn = () => {
        frameId = requestAnimationFrame(animationFrameFn);
        getValueAndCheckFn();
      };

      animationFrameFn();

      return () => {
        getValueAndCheckFn();
        cancelAnimationFrame(frameId);
      };
    }).pipe(distinctUntilChanged());
  }

  convertCSSUnitInPixel(cssUnit: string): number {
    return convertCSSUnitInPixel(cssUnit);
  }

  /**
   * CSS Custom Properties are not accessable in pseudo elements if they are defined in CSS only.
   * They have to be defined in inline styles [in DOM elements]
   * This function will read the value of a given CSS Custom Property of a source (element) and will
   * write this value in a target element under a given target property name
   */
  setCSSCustomPropertyForPseudoElements(object: CSSCustomPropertyPseudoElementWorkaroundObject) {
    let value = 'none';

    // getting target
    let targetElement: HTMLElement = object.targetElement;

    if (!targetElement) {
      targetElement = object.targetParentElement || this.getElement();
      if (object.targetElementCSSSelector) {
        targetElement = targetElement.querySelector(object.targetElementCSSSelector);
      }
    }

    // getting the source where the value should be
    let sourceElement: HTMLElement = object.sourceElement;

    if (!sourceElement) {
      sourceElement = object.sourceParentElement || this.getElement();
      if (object.sourceElementCSSSelector) {
        sourceElement = sourceElement.querySelector(object.sourceElementCSSSelector);
      }
    }

    // getting value
    value = this.getValue(object.sourcePropertyName, sourceElement);

    if (!object.targetPropertyName) {
      object.targetPropertyName = object.sourcePropertyName + '--pewa';
    }

    this.setValue(object.targetPropertyName, value, targetElement);
  }

  attemptStoppingBodyFromScrolling() {
    const el = this.windowRef.nativeWindow.document.body;
    return this.attemptStoppingHTMLElementFromScrolling(el);
  }

  motivateBodyTowardsScrolling() {
    const el = this.windowRef.nativeWindow.document.body;
    this.motivateHTMLElementTowardsScrolling(el);
  }

  attemptStoppingHTMLElementFromScrolling(el: HTMLElement) {

    const attempts = UtilCustomCSSPropertyService.noScrollAttemptsMap.get(el) || 0;

    if (attempts <= 0) {
      const overflowValue = el.style.getPropertyValue('overflow-y');
      UtilCustomCSSPropertyService.preAttemptOverflowYValueMap.set(el, overflowValue);
      el.style.setProperty('overflow-y', 'hidden');
    }

    UtilCustomCSSPropertyService.noScrollAttemptsMap.set(el, attempts + 1);

    return {
      reset: () => this.motivateHTMLElementTowardsScrolling(el)
    };

  }

  motivateHTMLElementTowardsScrolling(el: HTMLElement) {

    let attempts = UtilCustomCSSPropertyService.noScrollAttemptsMap.get(el);

    if (typeof attempts === 'number') {

      // decrease "attempts" by 1 and then set the new value
      UtilCustomCSSPropertyService.noScrollAttemptsMap.set(el, --attempts);

      if (attempts <= 0) {
        const overflowValue = UtilCustomCSSPropertyService.preAttemptOverflowYValueMap.get(el) || '';
        el.style.setProperty('overflow-y', overflowValue);
      }

    }

  }

}

export class UtilCustomCSSPropertyTestingService {

  setValue(variable: string, value: string = 'unset', domElement?: HTMLElement) {}

  remove(variable: string, domElement?: HTMLElement) {}

  getValue(variable: string, domElement?: HTMLElement) { return ''; }

  convertCSSUnitInPixel(cssUnit: string): number {
    return 0;
  }

  setCSSCustomPropertyForPseudoElements(object: CSSCustomPropertyPseudoElementWorkaroundObject) {}

  attemptStoppingBodyFromScrolling() {}

  motivateBodyTowardsScrolling() {}

  attemptStoppingHTMLElementFromScrolling(el: HTMLElement) {}

  motivateHTMLElementTowardsScrolling(el: HTMLElement) {}

}
