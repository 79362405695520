
/**
 * returns a number array from a given number to a given number
 * @param from - starting number of the array
 * @param to - ending number of the array
 */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export function getRangeArray(from = 0, to = 10): number[] {
  return [...Array(to + 1).keys()].slice(from);
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}


export function roundFractionalDigits(num: number, fractionalDigits = 0): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const factor = Math.pow(10, fractionalDigits);
  return (Math.round(num * factor) / factor);
}

/**
 * A function used to coerse a number from an @Input() intended to be a boolean value.
 * @returns number
 */
export function coerceNumber(value: any): number {
  return (!value || value === 'false') ? 0 : (typeof value === 'string') ? parseFloat(value) : (typeof value === 'boolean') ? 1 : value;
}

export function convertCSSUnitInPixel(cssUnit: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = cssUnit + ' sans-serif';
    const res = parseFloat(context.font);
    // console.log('from', cssUnit, 'in', res);
    return res;
}



export function getBits(str: string, increase: 1000 | 1024 = 1000): number {

  str = str.replace(',', '.');
  let bytes = 0;

  const powerIncrease = increase;
  const regex = /^[\x20]*([\d\.,]{1,})[ ]*([k|m|g|p|K|M|G|P]*)[\x20]*(b|B|bit|bits|Bit|Bits|byte|bytes|Byte|Bytes)[\x20]*$/; // NOSONAR
  const res = regex.exec(str);

  let power = 0;
  let usesBits = false;

  switch(res?.[2]) {
    case 'k': case 'K': power = 1; break;
    case 'm': case 'M': power = 2; break;
    case 'g': case 'G': power = 3; break;
    case 'p': case 'P': power = 1; break;
  }

  switch(res?.[3]) {
    case 'b': case 'B': case 'byte': case 'bytes': case 'Byte': case 'Bytes': usesBits = false; break;
    case 'bit': case 'Bit': case 'bits': case 'Bits': usesBits = true; break;
  }

  bytes = parseFloat(res[1]) * Math.pow(powerIncrease, power) * (usesBits ? 1 : 8);
  return bytes;
}

export class UtilNumbersHelper {

  /**
   * Increases a given number (num) by an increasing value and it makes sure that the new number stays with in a range (min- and maxLimit).
   * It loops around if a limit is hit.
   */
  static getIncreasedNumberWithLoopingAround(num: number, increasing: number, maxLimit: number, minLimit = 0) {

    if (maxLimit <= minLimit) {
      return minLimit;
    }

    function keepNumInRange() {
      if (num > maxLimit) {
        num = minLimit;
      }

      if (num < minLimit) {
        num = maxLimit;
      }
    }

    keepNumInRange();

    const n = Math.abs(increasing);
    const inc = increasing >= 0;

    for (let i = 0; i < n; i++) {
      num = inc ? (num + 1) : (num - 1);
      keepNumInRange();
    }

    return num;

  }

  /**
   * loops through an array according to a wanted increase level (if negative than it loops backwards) with a given starting index.
   * If the next index would be out of bound then the index will change to the opposite limit (0 or n).
   * It stops looping around if (1.) the until function returns true or if (2.) the next index already was used once.
   * returns index and element if the until function stopped the algorithmn or returns null values if the second use of
   * index was the stopping reason
   */
  static loopThroughElementsUntil<T>(arr: T[], startIndex: number, increaseBy: number, untilFn: (el: T, i: number, arr: T[]) => boolean) {

    let i = startIndex % arr.length;
    let stop = false;
    const indexSet = new Set<number>();
    const getNewIndex = () => {
      i = i + increaseBy;
      if (i < 0) {
        i = arr.length - 1;
      }
      if (i > arr.length - 1) {
        i = 0;
      }
      stop = indexSet.has(i);
      indexSet.add(i);
      if (stop) {
        i = null;
      }
    };

    while (!stop) {
      stop = untilFn(arr.at(i), i, arr);
      if (!stop) {
        getNewIndex();
      }
    }

    return {
      index: i,
      element: i !== null ? arr.at(i) : null
    };

  }

}
